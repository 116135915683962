.wrapper {
  padding: 88px 0;

  .container {
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

		.description {
			font-family: "NT Bau";
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.5px;
		}

    .containerlist {
			max-width: 842px;
      display: flex;
			flex-direction: column;
			gap: 16px;
      justify-content: center;
      align-items: center;
			margin-top: 64px;

			.firstlist {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
				grid-template-rows: 2fr;
				gap: 48px;
			}
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
		padding: 64px 0 0;
		margin: 0 0 96px;

    .container {

			.description {
				display: none;
			}

      .containerlist {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 72px;
				justify-content: center;
				align-items: center;
				margin-top: 32px;

				.firstlist {
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
					column-gap: 72px;
					row-gap: 24px;

					ul, li {
						width: 100%;
					}
				}
				
			}
    }
  }
}
